'use client';
import { createPortal } from 'react-dom';
import { CloseWhiteImage } from '../../../features/svgImages/CloseWhiteImage';
import { useH2uLayoutMenu } from '../useH2uLayoutMenu';
import { DeskTopMenus } from './DeskTopMenus';
import { MobileMenus } from './MobileMenus';
import type { Config } from 'u-next/config';

export const OverlayMenus: React.FC<{
  config: Config;
}> = ({ config }) => {
  const { isMenuOpen, setIsMenuOpen } = useH2uLayoutMenu();

  return isMenuOpen
    ? createPortal(
        <div className="tw-fixed tw-inset-0 tw-z-[1000] tw-animate-fadeIn tw-duration-100 tw-ease-linear">
          <div className="tw-absolute tw-inset-0 tw-bg-brand-lv1" />
          <div className="tw-relative">
            <div className="laptop:tw-hidden">
              <MobileMenus config={config} />
            </div>
            <div className="tw-hidden laptop:tw-block">
              <DeskTopMenus config={config} />
            </div>
          </div>
          <div className="tw-absolute tw-left-[0.5rem] tw-top-[0.5rem]">
            <div
              className="tw-relative tw-h-[4rem] tw-w-[4rem] tw-bg-main-100 tw-text-base-lv1"
              role="button"
              tabIndex={0}
              onClick={() => {
                setIsMenuOpen(false);
              }}
              onKeyDown={(e) => {
                if (e.key !== 'Enter') {
                  return;
                }
                setIsMenuOpen(false);
              }}
            >
              <CloseWhiteImage
                alt=""
                className="tw-absolute tw-inset-0 tw-m-auto tw-h-[2rem] tw-w-[2rem] tw-object-cover"
              />
            </div>
          </div>
        </div>,
        document.getElementById('modal') as Element
      )
    : null;
};
