import Link from 'next/link';
import { ArrowRightWhiteImage } from '../../../../features/svgImages/ArrowRightWhiteImage';
import { HelloeroRedImage } from '../../../../features/svgImages/HelloeroRedImage';
import { useMenuList } from '../useMenuList';
import type { Config } from 'u-next/config';

export const MobileMenus: React.FC<{
  config: Config;
}> = ({ config }) => {
  const { menus } = useMenuList({ config });

  return (
    <div className="tw-mx-auto tw-mt-[8rem] tw-w-[calc(100%-4rem)] tw-max-w-[40rem]">
      <div className="tw-grid tw-grid-cols-1 tw-gap-[4rem]">
        <div>
          <ul className="tw-grid tw-grid-cols-1 tw-gap-[1rem]">
            {menus.map(({ text, href }) => (
              <li key={href}>
                <Link
                  href={href}
                  className="tw-grid tw-grid-cols-[1fr,auto] tw-items-center"
                >
                  <div className="tw-text-h2 tw-text-base-lv1">{text}</div>
                  <ArrowRightWhiteImage
                    alt=""
                    className="tw-h-[1.5rem] tw-w-[1.5rem] tw-object-cover"
                  />
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <div className="tw-bg-brand-lv2 tw-py-[4rem]">
            <HelloeroRedImage alt="" className="tw-w-full tw-object-cover" />
          </div>
        </div>
      </div>
    </div>
  );
};
