import Link from 'next/link';
import { ArrowRightWhiteImage } from '../../../../features/svgImages/ArrowRightWhiteImage';
import { HelloeroRedRotateImage } from '../../../../features/svgImages/HelloeroRedRotateImage';
import { useMenuList } from '../useMenuList';
import type { Config } from 'u-next/config';

export const DeskTopMenus: React.FC<{
  config: Config;
}> = ({ config }) => {
  const { menus } = useMenuList({ config });

  return (
    <div className="tw-fixed tw-inset-[1rem]">
      <div className="tw-grid tw-h-full tw-grid-cols-2">
        <div className="tw-bg-brand-lv2 tw-p-[4rem]">
          <div className="tw-relative tw-h-full tw-w-full">
            <HelloeroRedRotateImage
              alt=""
              className="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-contain"
            />
          </div>
        </div>
        <div className="tw-mt-[8rem] tw-px-[2rem]">
          <ul className="tw-mx-auto tw-grid tw-max-w-[40rem] tw-grid-cols-1 tw-gap-[1rem]">
            {menus.map(({ text, href }) => (
              <li key={href}>
                <Link
                  href={href}
                  className="tw-grid tw-grid-cols-[1fr,auto] tw-items-center"
                >
                  <div className="tw-text-h2 tw-text-base-lv1">{text}</div>
                  <ArrowRightWhiteImage
                    alt=""
                    className="tw-h-[1.5rem] tw-w-[1.5rem] tw-object-cover"
                  />
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
