'use client';
import React from 'react';
import { HamburgerWhiteImage } from '../../../../features/svgImages/HamburgerWhiteImage';
import { useH2uLayoutMenu } from '../../useH2uLayoutMenu';

export const MenuButton: React.FC = () => {
  const { setIsMenuOpen } = useH2uLayoutMenu();

  return (
    <div
      className="tw-relative tw-h-[4rem] tw-w-[4rem] tw-bg-main-100 tw-text-base-lv1 tw-transition-colors hover:tw-bg-brand-lv1"
      role="button"
      tabIndex={0}
      onClick={() => {
        setIsMenuOpen(true);
      }}
      onKeyDown={(e) => {
        if (e.key !== 'Enter') {
          return;
        }
        setIsMenuOpen(true);
      }}
    >
      <HamburgerWhiteImage
        alt=""
        className="tw-absolute tw-inset-0 tw-m-auto tw-h-[2rem] tw-w-[2rem] tw-object-cover"
      />
    </div>
  );
};
