import React from 'react';
import { useUserInfo } from '../../../../hooks/useUserInfo';
import type { Config } from 'u-next/config';

export function useMenuList({ config }: { config: Config }) {
  const { isLoadedUserInfo, userInfo } = useUserInfo();
  const [menus, setMenus] = React.useState<
    {
      text: string;
      href: string;
    }[]
  >([]);

  React.useEffect(() => {
    setMenus([
      isLoadedUserInfo && userInfo.isLoggedIn
        ? {
            text: 'アカウント',
            href: config.ACCOUNT,
          }
        : {
            text: 'ログイン/登録',
            href: `${config.ACCOUNT}/login`,
          },
      {
        text: 'ヘルプ',
        href: config.HELP,
      },
    ]);
  }, [config.ACCOUNT, config.HELP, isLoadedUserInfo, userInfo.isLoggedIn]);

  return {
    menus,
  };
}
