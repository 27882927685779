import { atom, useAtom } from 'jotai';

const isMenuOpenAtom = atom(false);

export function useH2uLayoutMenu() {
  const [isMenuOpen, setIsMenuOpen] = useAtom(isMenuOpenAtom);
  return {
    isMenuOpen,
    setIsMenuOpen,
  };
}
